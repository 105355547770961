export const events = {
  HOMEPAGE_VISIT: 'Homepage_visit',
  START_BUILDING_HERO_CLICK: 'Homepage_header_CTA-login_click',
  HOMEPAGE_HEADER_NPM_LINK_CLICK: 'Homepage_header_NPM-link_click',
  HOMEPAGE_HEADER_DOCUMENTATION_LINK_CLICK: 'Homepage_header_Documentation-link_click',
  HOMEPAGE_HEADER_WHITEPAPER_LINK_CLICK: 'Homepage_header_Whitepaper-link_click',
  HOMEPAGE_HEADER_AUDIT_LINK_CLICK: 'Homepage_header_Audit-link_click',
  HOMEPAGE_HEADER_CTA_LOGIN_CLICK: 'Homepage_header_CTA-login_click',
  HOMEPAGE_REACT_NATIVE_CLICK: 'Homepage_React-native_click',
  HOMEPAGE_REACT_NATIVE_DOCUMENTATION_CLICK: 'Homepage_React-native_documentation_click',
  HOMEPAGE_REACT_NATIVE_COPY_CLICK: 'Homepage_React-native_copy_click',
  HOMEPAGE_REACT_CLICK: 'Homepage_React_click',
  HOMEPAGE_REACT_DOCUMENTATION_CLICK: 'Homepage_React_documentation_click',
  HOMEPAGE_REACT_COPY_CLICK: 'Homepage_React_copy_click',
  HOMEPAGE_FLUTTER_CLICK: 'Homepage_Flutter_click',
  HOMEPAGE_FLUTTER_DOCUMENTATION_CLICK: 'Homepage_Flutter_documentation_click',
  HOMEPAGE_FLUTTER_COPY_CLICK: 'Homepage_Flutter_copy_click',
  HOMEPAGE_NODE_CLICK: 'Homepage_Node_click',
  HOMEPAGE_NODE_DOCUMENTATION_CLICK: 'Homepage_Node_documentation_click',
  HOMEPAGE_NODE_COPY_CLICK: 'Homepage_Node_copy_click',
  HOMEPAGE_SOLIDITY_CLICK: 'Homepage_Solidity_click',
  HOMEPAGE_SOLIDITY_DOCUMENTATION_CLICK: 'Homepage_Solidity_documentation_click',
  HOMEPAGE_SOLIDITY_COPY_CLICK: 'Homepage_Solidity_copy_click',
  HOMEPAGE_FOOTER_CTA_LOGIN_CLICK: 'Homepage_footer_CTA-login_click',
  HOMEPAGE_FOOTER_NPM_LINK_CLICK: 'Homepage_footer_NPM-link_click',
  HOMEPAGE_FOOTER_DOCUMENTATION_LINK_CLICK: 'Homepage_footer_Documentation-link_click',
  HOMEPAGE_FOOTER_WHITEPAPER_LINK_CLICK: 'Homepage_footer_Whitepaper-link_click',
  HOMEPAGE_FOOTER_AUDIT_LINK_CLICK: 'Homepage_footer_Audit-link_click',
  HOMEPAGE_FOOTER_T_AND_C_CLICK: 'Homepage_footer_T&C_click',
  HOMEPAGE_FOOTER_PRIVACY_CLICK: 'Homepage_footer_Privacy_click'
};


