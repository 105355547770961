import React from 'react';
import styles from './styles.module.scss';
import img1 from './../../shared/assets/partnersLogo/full-mina-logo.png';
import img2 from './../../shared/assets/partnersLogo/full-arbitrum-logo.png';
import img3 from './../../shared/assets/partnersLogo/Polkadot-Logo.png';
import img4 from './../../shared/assets/partnersLogo/aleph.png';
import img5 from './../../shared/assets/partnersLogo/near.png';
import img6 from './../../shared/assets/partnersLogo/infinity.png';
import img7 from './../../shared/assets/partnersLogo/nibiry.png';
import img8 from './../../shared/assets/partnersLogo/secret.png';
import img9 from './../../shared/assets/partnersLogo/solana.png';
import img10 from './../../shared/assets/partnersLogo/SuiFoundation.png';

export const Marquee = () => (
    <div className={styles.marquee}>
        <div className={styles.marqueeContent}>
            <img src={img1} alt="image1"/>
            <img src={img2} alt="image2"/>
            <img src={img3} alt="image3"/>
            <img src={img4} alt="image4"/>
            <img src={img5} alt="image5"/>
            <img src={img6} alt="image6"/>
            <img src={img7} alt="image7"/>
            <img src={img8} alt="image8"/>
            <img src={img9} alt="image9"/>
            <img src={img10} alt="image10"/>
        </div>
        <div className={styles.marqueeContent}>
            <img src={img1} alt="image1"/>
            <img src={img2} alt="image2"/>
            <img src={img3} alt="image3"/>
            <img src={img4} alt="image4"/>
            <img src={img5} alt="image5"/>
            <img src={img6} alt="image6"/>
            <img src={img7} alt="image7"/>
            <img src={img8} alt="image8"/>
            <img src={img9} alt="image9"/>
            <img src={img10} alt="image10"/>
        </div>
    </div>
);

export default Marquee;
