export interface CodeObjectType {
    link: string;
    code: string;
    name?: string;
}


export const codeUSE: Record<string, CodeObjectType> = {
    'React': {
        link: 'https://docs.reclaimprotocol.org/react',
        name: 'react',
        code: 'import { ReclaimClient } from \'v2-reclaim-sdk\'\n' +
                '  \n' +
            '      const getVerificationReq = async () => {\n' +
            '      const providers = [\'bybit-balance\'];\n' +
            '      const PRIVATE_KEY ="" // your app private key.\n' +
            '      const providerV2 = await reclaimClient.buildHttpProviderV2ByName(\n' +
            '        providers\n' +
            '      )\n' +
            '      const requestProofs = reclaimClient.buildRequestedProofs(\n' +
            '        providerV2,\n' +
            '        reclaimClient.getAppCallbackUrl()\n' +
            '      )\n' +
            '      reclaimClient.setSignature(\n' +
            '        await getSignature(requestProofs, PRIVATE_KEY) \n' +
            '      )\n' +
            '      const req = await reclaimClient.createVerificationRequest(providers)\n' +
            '      console.log(\'req\', req.template)\n' +
            '      req.on(\'success\', (data: any) => {\n' +
            '        if (data) {\n' +
            '          const proofs = data\n' +
            '          console.log(\'success\', proofs[0])\n' +
            '          // assuming you are maintaining the state of the extracted values\n' +
            '          setExtracted(JSON.stringify(proofs[0].extractedParameterValues))\n' +
            '        }\n' +
            '      })\n' +
            '      setVerificationReq(req)\n' +
            '      await req.start()\n' +
            '    }\n' +
            '    const getSignature = async (requestProofs: any, appSecret: string) => {\n' +
            '      const signature = await reclaimClient.getSignature(\n' +
            '        requestProofs,\n' +
            '        appSecret\n' +
            '      )\n' +
            '      return signature\n' +
            '    }'
    },
    'React Native': {
        link: 'https://docs.reclaimprotocol.org/react-native',
        name: 'reactNative',
        code: 'import React from \'react\';\n' +
                'import {SafeAreaView, StyleSheet, useColorScheme} from \'react-native\';\n' +
            'import {Colors} from \'react-native/Libraries/NewAppScreen\';\n' +
            'import {ReclaimHttps} from \'@reclaimprotocol/reclaim-react-native\';\n' +
            ' \n' +
            'function App(): JSX.Element {\n' +
            '  return (\n' +
            '    <SafeAreaView style={backgroundStyle.container}>\n' +
            '      <ReclaimHttps\n' +
            '        requestedProofs={[\n' +
            '          {\n' +
            '            url: \'https://bookface.ycombinator.com/home\',\n' +
            '            loginUrl: \'https://bookface.ycombinator.com/home\',\n' +
            '            loginCookies: [\'_sso.key\'], \n' +
            '            responseSelections: [\n' +
            '              {\n' +
            '                responseMatch:\n' +
            '                  \'\\{"id":{{YC_USER_ID}},.*?waas_admin.*?:{.*?}.*?:\\{.*?}.*?(?:full_name|first_name).*?}\',\n' +
            '              },\n' +
            '            ],\n' +
            '          },\n' +
            '        ]}\n' +
            '        // context="Proving on 2023 for eth India" \n' +
            '        title="YC Login"\n' +
            '        subTitle="Prove you have a YC Login"\n' +
            '        cta="Prove"\n' +
            '        onSuccess={proofs => {\n' +
            '          /*do something*/\n' +
            '          console.log(\'proofs\', proofs);\n' +
            '        }}\n' +
            '        onFail={e => {\n' +
            '          /*do something*/\n' +
            '          console.log(\'Error\', e);\n' +
            '        }}\n' +
            '        buttonStyle={{backgroundColor: \'black\'}}\n' +
            '        buttonTextStyle={{color: \'blue\'}}\n' +
            '        onStatusChange={(text: string) => {\n' +
            '          console.log("Status is: ", text);\n' +
            '        }}\n' +
            '      />\n' +
            '    </SafeAreaView>\n' +
            '  );\n' +
            '}'
    },
    'Flutter': {
        link: 'https://docs.reclaimprotocol.org/flutter',
        name: 'flutter',
        code: 'import \'package:flutter/material.dart\';\n' +
                'import \'package:reclaim_flutter/reclaim_flutter.dart\';\n' +
            ' \n' +
            'void main() {\n' +
            '  runApp(const MainApp());\n' +
            '}\n' +
            ' \n' +
            '// Init a GlobalKey and pass it to ReclaimHttps widget\n' +
            'final httpEqualKey = GlobalKey<ReclaimHttpsState>();\n' +
            ' \n' +
            'class MainApp extends StatelessWidget {\n' +
            '  const MainApp({super.key});\n' +
            ' \n' +
            '  @override\n' +
            '  Widget build(BuildContext context) {\n' +
            '    return MaterialApp(\n' +
            '      home: Scaffold(\n' +
            '        body: ListView.builder(\n' +
            '          itemCount: 1,\n' +
            '          itemBuilder: (BuildContext context, int index) {\n' +
            '            return Center(\n' +
            '              child: ReclaimHttps(\n' +
            '                key: httpEqualKey,\n' +
            '                requestedProofs: [\n' +
            '                  RequestedProof(\n' +
            '                    url: \'https://bookface.ycombinator.com/home\',\n' +
            '                    loginUrl: \'https://bookface.ycombinator.com/home\',\n' +
            '                    loginCookies: [\'_sso.key\'],\n' +
            '                    responseSelections: [\n' +
            '                      ResponseSelection(\n' +
            '                        responseMatch: \'{&quot;id&quot;:{{YC_USER_ID}},.*?waas_admin.*?:{.*?}.*?:\\{.*?}.*?(?:full_name|first_name).*?}\',\n' +
            '                      ),\n' +
            '                    ],\n' +
            '                  ),\n' +
            '                ],\n' +
            '                title: "YC Login",\n' +
            '                subTitle: "Prove you have a YC Login",\n' +
            '                cta: "Prove",\n' +
            '                onStatusChange: (status) =>\n' +
            '                  print(\'Status changed to : $status\'),\n' +
            '                onSuccess: (proofs) {\n' +
            '                  print(\'proofs: $proofs\');\n' +
            '                },\n' +
            '                onFail: (Exception e) {\n' +
            '                  print(\'Error: $e\');\n' +
            '                },\n' +
            '                showShell: true,\n' +
            '                shellStyles: BoxDecoration(\n' +
            '                  border: Border.all(color: Colors.red, width: 2.0),\n' +
            '                ),\n' +
            '              ),\n' +
            '            );\n' +
            '          },\n' +
            '        ),\n' +
            '      ),\n' +
            '    );\n' +
            '  }\n' +
            '}'
    },
    'Node': {
        link: 'https://docs.reclaimprotocol.org/node',
        name: 'node',
        code: 'app.get("/request-proofs", async(req, res) => {\n' +
                '    const request = reclaim.requestProofs({\n' +
            '        title: "My DeFi app", // Name of your application\n' +
            '        baseCallbackUrl: "http://<YOUR_BACKEND_SERVER_URL>/callback", \n' +
            '        callbackId: "<UNIQUE_IDENTIFIER_GOES_HERE>", // optional\n' +
            '        contextMessage: "Airdrop for Reclaim Users!", //optional\n' +
            '        contextAddress: userAddress, //optional\n' +
            '        requestedProofs: [\n' +
            '            new reclaim.CustomProvider({\n' +
            '                provider: \'bybit-balance\',\n' +
            '                payload: {}\n' +
            '            }),\n' +
            '        ],\n' +
            '    });\n' +
            ' \n' +
            '    const { callbackId } = request;\n' +
            '    const reclaimUrl = await request.getReclaimUrl();\n' +
            '    // Store the callback Id and Reclaim URL in your database\n' +
            '    // ...\n' +
            '    res.json({ reclaimUrl });\n' +
            '     // display this reclaimUrl as a QR code on laptop or as a link on mobile devices for users to initiate creating proofs\n' +
            '})'
    },
    'Solidity': {
        name: 'solidity',
        link: 'https://docs.reclaimprotocol.org/solidity',
        code: '// Default code here'
    }
}
