import {Header} from "../header";
import styles from './styles.module.scss';
import arrowRightWhite from "../../shared/assets/icon/Arrow-Right-white.svg";
import clsx from "clsx";
import xIcon from "../../shared/assets/icon/x-icon.svg";
import telegramIcon from "../../shared/assets/icon/telegram-icon.svg";
import React, {useState} from "react";
import logo from "../../shared/assets/icon/Logo.svg";
import {sendRequest} from "../../App";
import arrowRightGray from "../../shared/assets/icon/Arrow-Right-gray.svg";

export const Footer = () => {
    const [email, setEmail] = useState('');
    const [error, setError] = useState<boolean>(false);
    const [isFocused, setIsFocused] = useState(false);
    const [sendSuccess, setSendSuccess] = useState(false);

    const handleFocus = () => {
        setIsFocused(true);
    };

    const handleBlur = () => {
        setIsFocused(false);
    };

    const handleKeyDown = async (e: any) => {
        if (e.key === 'Enter') {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            const isValid = emailRegex.test(email);
            if (!isValid && email) {
                setError(true)
                return;
            } else {
                setError(false)
            }
            const res = await sendRequest(email, error);
            setSendSuccess(res);
        }
    };

    return (
        <div className={styles.footer}>
            <div className={styles.wrapper}>
                <div className={styles.footerTextWrapper}>
                    <div>
                        <div className={styles.footerText}>
                            Stay in the loop
                        </div>
                        <div className={clsx(styles.footerBlockSubscribe, styles.footerBlockSubscribeMobile)}>
                            <div className={styles.footerBlockInputWrapper}>
                                {!sendSuccess ? <>
                                        <input type="email"
                                               className={clsx(styles.footerBlockInput, {
                                                   [styles.footerBlockInputError]: error
                                               })}
                                               placeholder={isFocused ? '' : 'Your e-mail'}
                                               value={email}
                                               onBlur={(e) => {
                                                   const email = e.target.value;
                                                   const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                                                   const isValid = emailRegex.test(email);
                                                   if (!isValid && email) {
                                                       setError(true)
                                                   } else {
                                                       setError(false)
                                                   }
                                                   handleBlur();
                                               }}
                                               onChange={(e) => {
                                                   const email = e.target.value;
                                                   setEmail(email)
                                               }}
                                               onFocus={handleFocus}
                                               onKeyDown={handleKeyDown}/>
                                        <button onClick={() => sendRequest(email, error)}>
                                            <img src={arrowRightGray} alt=''/>
                                        </button>
                                    </>:
                                    <p className={styles.footerBlockInputSendEmail}>Thanks for signing up!</p> }
                                {error && <p className={styles.footerBlockInputErrorEmail}>Invalid email</p>}
                            </div>
                            <div className={styles.footerBlockSocialWrapper}>
                                <a href="https://twitter.com/reclaimprotocol" target="_blank">
                                    <div
                                        className={styles.footerBlockIconSocial}>
                                        <img src={xIcon} alt=""/>
                                    </div>
                                </a>
                                <a href="https://t.me/protocolreclaim/1" target="_blank">
                                    <div
                                        className={styles.footerBlockIconSocial}>
                                        <img src={telegramIcon} alt=""/>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                    <Header colorRevert fromFooter/>
                </div>
                <div className={styles.footerBottom}>
                    <div className={clsx(styles.footerBlockSubscribe, styles.footerBlockSubscribeDesktop)}>
                        <div className={styles.footerBlockInputWrapper}>
                            {!sendSuccess ? <>
                                    <input type="email"
                                           className={clsx(styles.footerBlockInput, {
                                               [styles.footerBlockInputError]: error
                                           })}
                                           placeholder={isFocused ? '' : 'Your e-mail'}
                                           value={email}
                                           onBlur={(e) => {
                                               const email = e.target.value;
                                               const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                                               const isValid = emailRegex.test(email);
                                               if (!isValid && email) {
                                                   setError(true)
                                               } else {
                                                   setError(false)
                                               }
                                               handleBlur();
                                           }}
                                           onChange={(e) => {
                                               const email = e.target.value;
                                               setEmail(email)
                                           }}
                                           onFocus={handleFocus}
                                           onKeyDown={handleKeyDown}/>
                                    <button onClick={() => sendRequest(email, error)}>
                                        <img src={arrowRightGray} alt=''/>
                                    </button>
                                </>:
                                <p className={styles.footerBlockInputSendEmail}>Thanks for signing up!</p> }
                            {error && <p className={styles.footerBlockInputErrorEmail}>Invalid email</p>}
                        </div>
                        <div className={styles.footerBlockSocialWrapper}>
                            <a href="https://twitter.com/reclaimprotocol" target="_blank">
                                <div
                                    className={styles.footerBlockIconSocial}>
                                    <img src={xIcon} alt=""/>
                                </div>
                            </a>
                            <a href="https://t.me/protocolreclaim/1" target="_blank">
                                <div
                                    className={styles.footerBlockIconSocial}>
                                    <img src={telegramIcon} alt=""/>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className={styles.footerLogoWrapper}>
                        <p>© 2024 CreatorOS Inc. All rights reserved.</p>
                        <img className={clsx(styles.footerLogo, styles.footerImgRevertLogo)} src={logo} alt="logo"/>
                    </div>
                    {/*<div className={styles.footerBottomLinks}>*/}
                    {/*    <a href="#">Terms Of Use</a>*/}
                    {/*    <a href="#">Privacy Policy</a>*/}
                    {/*</div>*/}
                </div>
            </div>
        </div>
    )
}
