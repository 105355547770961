export const faq = [
    {
        question: 'What is Reclaim Protocol?',
        answer: {
            title: 'What is Reclaim Protocol?',
            text: ['Reclaim Protocol is a set of tools that allows users to extract & self-govern any piece of data they see on any website. For example, you could ask the user to log into their bank and prove the account balance. You could ask your users to log into Uber and prove that they\'ve taken over 50 rides this year.', 'All of this happens without compromising security and without the need for the official APIs.', 'You can connect to any website on the internet.', 'Over 250 data providers created already by our community!']
        }
    },
    {
        question: 'What does Reclaim Protocol do?',
        answer: {
            title: 'What does Reclaim Protocol do?',
            text: ['Reclaim Protocol creates digital signatures, known as zero knowledge proofs, of users\' data on any website.', 'These digital signatures are computed completely on the client side.', 'Meaning, it is private and secure.', 'When the user shares this proof with your app, you can be certain that its authenticity and integrity haven\'t been compromised!']
        }
    },
    {
        question: 'How does Reclaim Protocol work?',
        answer: {
            title: 'How does Reclaim Protocol work?',
            text: ['Reclaim Protocol makes HTTPS verifiable. At a high level, the HTTPS request and response are routed through a network of HTTPS Proxies.', 'These proxies provide their digital signatures to the responses sent by the websites.', 'The user\'s device then generates a cryptographic proof using the encrypted response and the digital signature.', 'This proof is that of the data that was present in the response like their bank balance or Uber rides count.', 'If you want to learn more read our whitepaper!']
        }
    },
    {
        question: 'Is that an MITM attack?',
        answer: {
            title: 'Is that an MITM attack?',
            text: ['The short answer is no.', 'But the question isn\'t irrelevant. Reclaim uses HTTPS Proxies that forward the users\' requests and responses. These are requests that are encrypted on the TLS layer.', 'The HTTPS Proxies on Reclaim Protocol Network do not have access to the keys that decrypt these requests and responses.', 'To perform an MITM attack, the proxies need to have the decryption keys.', 'You can also see a more detailed cryptographic argument here.']
        }
    },
    {
        question: 'What are the trust assumptions here?',
        answer: {
            title: 'What are the trust assumptions here?',
            text: ['As of today, all the nodes on the Reclaim Protocol Network are run by a single corporate entity, us.', 'So, you need to trust us that we won\'t provide digital signatures for requests and responses that were never made.', 'However, soon we will be having decentralized nodes in a way that makes sure no one is capable of providing signatures for events that never happened.']
        }
    },
    {
        question: 'If the software is open-sourced, do I need to pay?',
        answer: {
            title: 'If the software is open-sourced, do I need to pay?',
            text: ['You can use Reclaim Protocol without ever needing to pay for it. You can clone the repos and run the network and clients yourself.', 'However, Reclaim Protocol team creates, publishes and supports software that is paid.', 'Additionally, we provide a suite of developer tools and managed services to make your development and dev ops as cheap as possible.', 'Right now, during the open beta, everything is free of charge!']
        }
    },
    {
        question: 'Do my users have to install anything extra?',
        answer: {
            title: 'Do my users have to install anything extra?',
            text: ['No.', 'Users can tap on a button on your app or scan a QR code on your website and they\'ll be guided through generating the proof on their mobile device.', 'They do need to use their mobile phone but they don\'t need to install any app.', 'Reclaim client software uses Appclips on iOS and InstantApps on Android.']
        }
    }
]
