import logo from '../../shared/assets/icon/Logo.svg';
import arrowTopRight from '../../shared/assets/icon/Arrow-Top-Right.svg';
import clsx from "clsx";
import menu from './../../shared/assets/icon/menu.svg';
import x from './../../shared/assets/icon/x.svg';
import React, {useState} from "react";
import * as amplitude from '@amplitude/analytics-browser';
import { events } from '../../utils/constants';
import arrowRightWhite from "../../shared/assets/icon/Arrow-Right-white.svg";
import {handleSignIn} from "../../App";
import styles from './styles.module.scss';

export const Header = ({colorRevert, fromFooter}: any) => {
    const [menuOpen, setMenuOpen] = useState<boolean>(false);
    const handleOpenMenu = () => {
        setMenuOpen(!menuOpen)
        if(menuOpen) {
            amplitude.track(`Mobile Menu Clicked`)
        }
    }

    return (
        <div className={clsx(styles.header, {
            [styles.menuOpen]: menuOpen,
            [styles.headerFooter]: fromFooter,
        })}>
            {!fromFooter && <img className={clsx(styles.headerLogo, {
                [styles.headerImgRevertLogo]: colorRevert
            })} src={logo} alt="logo"/> }
            <nav className={clsx(styles.headerNav, {
                [styles.menuOpenNav]: menuOpen,
                [styles.headerNavFooter]: fromFooter,
            })}>
                <a href="https://www.npmjs.com/package/@reclaimprotocol/js-sdk"
                   className={clsx(styles.headerNavLink, {
                       [styles.headerNavLinkRevert]: colorRevert
                   })}
                   onClick={() => {
                       amplitude.track(fromFooter ? events.HOMEPAGE_FOOTER_NPM_LINK_CLICK : events.HOMEPAGE_HEADER_NPM_LINK_CLICK)
                   }}
                >Npm
                    <img src={arrowTopRight}
                         className={colorRevert ? styles.headerImgRevert : ''}
                         alt="arrow_top_right"/></a>
                <a href="https://docs.reclaimprotocol.org/"
                   className={clsx(styles.headerNavLink, {
                       [styles.headerNavLinkRevert]: colorRevert
                   })}
                   onClick={() => {
                       amplitude.track(fromFooter ? events.HOMEPAGE_FOOTER_DOCUMENTATION_LINK_CLICK : events.HOMEPAGE_HEADER_DOCUMENTATION_LINK_CLICK)
                   }}
                >Documentation
                    <img src={arrowTopRight}
                         className={colorRevert ? styles.headerImgRevert : ''}
                         alt="arrow_top_right"/></a>
                <a href="https://drive.google.com/file/d/1wmfdtIGPaN9uJBI1DHqN903tP9c_aTG2/view"
                   className={clsx(styles.headerNavLink, {
                       [styles.headerNavLinkRevert]: colorRevert
                   })}
                   onClick={() => {
                       amplitude.track(fromFooter ? events.HOMEPAGE_FOOTER_WHITEPAPER_LINK_CLICK : events.HOMEPAGE_HEADER_WHITEPAPER_LINK_CLICK)
                   }}
                >Whitepaper
                    <img src={arrowTopRight}
                         className={colorRevert ? styles.headerImgRevert : ''}
                         alt="arrow_top_right"/></a>
                <a href="https://www.zksecurity.xyz/blog/posts/reclaim/"

                   onClick={() => {
                       amplitude.track(fromFooter ? events.HOMEPAGE_FOOTER_AUDIT_LINK_CLICK : events.HOMEPAGE_HEADER_AUDIT_LINK_CLICK)
                   }}
                   className={clsx(styles.headerNavLink, {
                       [styles.headerNavLinkRevert]: colorRevert
                   })}
                >Security Audit
                    <img src={arrowTopRight}
                         className={colorRevert ? styles.headerImgRevert : ''}
                         alt="arrow_top_right"/></a>
            </nav>
            {!fromFooter && <div className={styles.headerBurgerWrapper}>
                <img className={clsx(styles.headerBurger, {
                    [styles.headerBurgerActive]: menuOpen
                })} onClick={handleOpenMenu} src={menuOpen ? x : menu} alt="hamburger"/>
                <div className={styles.headerButton}>
                    <a
                        onClick={() => {
                            handleSignIn()
                            amplitude.track(events.START_BUILDING_HERO_CLICK)
                        }}
                        style={{cursor: "pointer"}}
                    >
                        Get started
                    </a>
                    <img src={arrowRightWhite} alt=''/>
                </div>
            </div> }
        </div>
    )
}
