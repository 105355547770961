import React, {Fragment, useEffect, useState} from 'react';
import * as amplitude from "@amplitude/analytics-browser"
import {Header} from "./widgets/header";
import arrowRight from './shared/assets/icon/Arrow-Right.svg'
import arrowRightGray from './shared/assets/icon/Arrow-Right-gray.svg'
import mockup from './shared/assets/icon/Mockup.svg';
import react from './shared/assets/icon/React-icon.svg';
import flutter from './shared/assets/icon/flutter.svg';
import node from './shared/assets/icon/Node.js_logo.svg';
import document from './shared/assets/icon/document.svg';
import duplicate from './shared/assets/icon/duplicate.svg';
import chevron from './shared/assets/icon/chevron.svg';
import telegramIcon from './shared/assets/icon/telegram-icon.svg';
import xIcon from './shared/assets/icon/x-icon.svg';
import clsx from "clsx";
import {faq} from "./faq";
import {Footer} from "./widgets/footer";
import {CodeObjectType, codeUSE} from "./codeUse";
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { materialDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import copy from 'clipboard-copy';
import Marquee from "./widgets/Marquee";
import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth"
import { app } from './config/firebase';
import { events } from './utils/constants';
import styles from './styles.module.scss'

const myMaterialTheme = {
    ...materialDark,
    'pre[class*="language-"]': {
        ...materialDark['pre[class*="language-"]'],
        background: '#0A1019',
        margin: 0,
    },
    'code[class*="language-"]': {
        ...materialDark['code[class*="language-"]'],
        background: '#0A1019',
    },
}

 export const auth = getAuth(app)
 export const handleSignIn = async () => {

   const provider = new GoogleAuthProvider()
   try {

     const userCredential = await signInWithPopup(auth, provider)
     const idToken = await userCredential.user.getIdToken()
     amplitude.track(`${userCredential.user.displayName} - Logged In`)
     const LOGIN_URL = `https://api.reclaimprotocol.org/api/users/login`

     const response = await fetch(LOGIN_URL, {
       method: "POST",
       headers: {
         Authorization: `Bearer ${idToken}`,
         "Content-Type": "application/json",
       },
     })

     const data = await response.json()

     if (data.isSuccess) {
       const payload = {
         [`firebase:authUser:${process.env.REACT_APP_PUBLIC_FIREBASE_API_KEY}:[DEFAULT]`]:
           JSON.stringify(userCredential.user),
         user: JSON.stringify(data),
         userType: JSON.stringify(data?.user?.type),
       }
       window.location.assign(
         `https://dev.reclaimprotocol.org/firebase-session?payload=${JSON.stringify(
           payload
         )}`
       )
       // Handle successful login here, if needed
     } else {
       // Handle failure here, if needed
     }
   } catch (error) {
     console.error("Error occurred:", error)
     // Handle error here, if needed
   }
 }


export const sendRequest = async (email: string, error: boolean) => {
    if(error) return false;
    try {
        const res = await fetch(`https://api.beehiiv.com/v2/publications/${process.env.REACT_APP_BEEHIIV}/subscriptions`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                email: email
            })
        })
        if(res) {
            return true
        }
    } catch (e) {
        console.log(e);
    }
    return false;
};

const App = () => {
    const [buttonCodeActive, setButtonCodeActive] = useState<string>('React');
    const [answer, setAnswer] = useState(faq[0].answer)
    const [answerIndex, setAnswerIndex] = useState<number>(0);
    const [codeLib, setCodeLib] = useState<CodeObjectType>(codeUSE.React)
    const [email, setEmail] = useState<string>('');
    const [error, setError] = useState<boolean>(false);
    const [isFocused, setIsFocused] = useState(false);
    const [sendSuccess, setSendSuccess] = useState(false);

    const handleFocus = () => {
        setIsFocused(true);
    };

    const handleBlur = () => {
        setIsFocused(false);
    };

    const handleKeyDown = async (e: any) => {
        if (e.key === 'Enter') {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            const isValid = emailRegex.test(email);
            if (!isValid && email) {
                setError(true)
                return;
            } else {
                setError(false)
            }
            const res = await sendRequest(email, error);
            setSendSuccess(res)
        }
    };

     useEffect(() => {
       amplitude.init("fd493e02fcad199d70938c4b48db0a23",{
        defaultTracking: true,
       })
       amplitude.track(events.HOMEPAGE_VISIT)
     }, [])

    const handleButtonCode = (event: any) => {
        const { name } = event.target;
        setButtonCodeActive(name);
        // amplitude.track(` ${name} - code Clicked`)
        setCodeLib(codeUSE[name]);
         switch (name) {
           case "React":
             amplitude.track(events.HOMEPAGE_REACT_CLICK)
             break
           case "React Native":
             amplitude.track(events.HOMEPAGE_REACT_NATIVE_CLICK)
             break
           case "Flutter":
             amplitude.track(events.HOMEPAGE_FLUTTER_CLICK)
             break
           case "Node":
             amplitude.track(events.HOMEPAGE_NODE_CLICK)
             break
           case "solidity":
             amplitude.track(events.HOMEPAGE_SOLIDITY_CLICK)
             break
           default:
             // Handle default case
             break
         }
    }

    const handleFaq = (el: any, index: number, question: string) => {
        setAnswer(el)
        setAnswerIndex(index);
        amplitude.track(`Homepage_FAQ_Question-${index+1}_Click`)
    }

    const handleCopyCode = () => {
        void copy(codeLib.code);

         switch (codeLib.name) {
           case "react":
             amplitude.track(events.HOMEPAGE_REACT_COPY_CLICK)
             break
           case "reactNative":
             amplitude.track(events.HOMEPAGE_REACT_NATIVE_COPY_CLICK)
             break
           case "flutter":
             amplitude.track(events.HOMEPAGE_FLUTTER_COPY_CLICK)
             break
           case "node":
             amplitude.track(events.HOMEPAGE_NODE_COPY_CLICK)
             break
           case "solidity":
             amplitude.track(events.HOMEPAGE_SOLIDITY_COPY_CLICK)
             break
           default:
             // Handle default case
             break
         }
    }

    return (
      <>
        <div className={clsx(styles.wrapper, styles.app)}>
          <Header />
          <div className={styles.topBlockWrapper}>
            <div className={styles.titleWrapper}>
              <h1 className={styles.title}>
                Unlock <span>data transfers</span> for your users from{" "}
                <span>any&nbsp;website</span> to yours
              </h1>
              <p className={styles.description}>
                Get data without service APIs
              </p>
            </div>
            <div className={styles.mockupBlock}>
              <div className={styles.mockupBlockText}>
                  <div>
                      <h2 className={styles.mockupBlockTitle}>
                          Get the latest updates
                      </h2>
                      <div className={styles.mockupBlockDescription}>
                          <div>Subscribe&nbsp;to&nbsp;our</div>
                          <div className={styles.mockupBlockDescriptionSpan}>
                              <span>email list</span>and<span>socials</span>!
                          </div>
                      </div>
                  </div>
                  <div className={styles.mockupBlockSubscribe}>
                      <div className={styles.mockupBlockInputWrapper}>
                          {!sendSuccess ? <>
                              <input type="email"
                                 className={clsx(styles.mockupBlockInput, {
                                     [styles.mockupBlockInputError]: error
                                 })}
                                 placeholder={isFocused ? '' : 'Your e-mail'}
                                 value={email}
                                 onBlur={(e) => {
                                     const email = e.target.value;
                                     const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                                     const isValid = emailRegex.test(email);
                                     if (!isValid && email) {
                                         setError(true)
                                     } else {
                                         setError(false)
                                     }
                                     handleBlur();
                                 }}
                                 onChange={(e) => {
                                     const email = e.target.value;
                                     setEmail(email)
                                 }}
                                 onFocus={handleFocus}
                                 onKeyDown={handleKeyDown}/>
                          <button onClick={() => sendRequest(email, error)}>
                              <img src={arrowRightGray} alt=''/>
                          </button>
                              </>:
                          <p className={styles.mockupBlockInputSendEmail}>Thanks for signing up!</p> }
                          {error && <p className={styles.mockupBlockInputErrorEmail}>Invalid email</p>}
                      </div>
                      <div className={styles.mockupBlockSubscribeOR}>or</div>
                      <div className={styles.mockupBlockSocialWrapper}>
                          <a href="https://twitter.com/reclaimprotocol" target="_blank">
                              <div
                                  className={clsx(styles.mockupBlockIconSocial, styles.mockupBlockIconSocialColorBlack)}>
                                <img src={xIcon} alt=""/>
                            </div>
                          </a>
                      <a href="https://t.me/protocolreclaim/1" target="_blank">
                              <div
                                  className={clsx(styles.mockupBlockIconSocial, styles.mockupBlockIconSocialColorTelegram)}>
                                <img src={telegramIcon} alt=""/>
                              </div>
                          </a>
                  </div>
              </div>

            </div>
                <div className={styles.mockupImgWrapper}>
                    <img src={mockup} alt=''/>
                    <video
                        src={
                            "https://aplhasite.s3.ap-south-1.amazonaws.com/video-phone.mp4"
                  }
                  //src={demoVideo}
                  muted
                  loop
                  autoPlay
                  className={styles.mockupVideo}
                />
              </div>
            </div>
          </div>
        </div>
        <Marquee />
        <div className={clsx(styles.wrapper, styles.app)}>
          <div className={styles.integrationCodeBlock}>
            <h2 className={styles.integrationCodeBlockTitle}>
              Integrate Reclaim Into Your App Now
            </h2>
            <div className={styles.integrationCodeBlockButtonWrapper}>
              <button
                className={clsx(styles.integrationCodeBlockButton, {
                  [styles.integrationCodeBlockButtonActive]:
                    buttonCodeActive === "React",
                })}
                onClick={handleButtonCode}
                name='React'
              >
                <img
                  src={react}
                  className={
                    buttonCodeActive === "React" ? styles.activeImage : ""
                  }
                  alt=''
                />
                React
              </button>
              <button
                className={clsx(styles.integrationCodeBlockButton, {
                  [styles.integrationCodeBlockButtonActive]:
                    buttonCodeActive === "React Native",
                })}
                onClick={handleButtonCode}
                name='React Native'
              >
                <img
                  src={react}
                  className={
                    buttonCodeActive === "React Native"
                      ? styles.activeImage
                      : ""
                  }
                  alt=''
                />
                React Native
              </button>
              <button
                className={clsx(styles.integrationCodeBlockButton, {
                  [styles.integrationCodeBlockButtonActive]:
                    buttonCodeActive === "Flutter",
                })}
                onClick={handleButtonCode}
                name='Flutter'
              >
                <img
                  src={flutter}
                  className={
                    buttonCodeActive === "Flutter" ? styles.activeImage : ""
                  }
                  alt=''
                />
                Flutter
              </button>
              <button
                className={clsx(styles.integrationCodeBlockButton, {
                  [styles.integrationCodeBlockButtonActive]:
                    buttonCodeActive === "Node",
                })}
                onClick={(e)=>{
                  handleButtonCode(e)
                }}
                name='Node'
              >
                <img
                  src={node}
                  className={
                    buttonCodeActive === "Node" ? styles.activeImage : ""
                  }
                  alt=''
                />
                Node
              </button>
              {/*<button className={clsx(styles.integrationCodeBlockButton, {*/}
              {/*    [styles.integrationCodeBlockButtonActive]: buttonCodeActive === 'Solidity'*/}
              {/*})} onClick={handleButtonCode} name='Solidity'><img src={solidity}*/}
              {/*                                                    className={buttonCodeActive === 'Solidity' ? styles.activeImage : ''}*/}
              {/*                                                    alt=""/>Solidity*/}
              {/*</button>*/}
            </div>
            <div className={styles.integrationCodeEdit}>
              <div className={styles.integrationCodeBlockEditButtonWrapper}>
                <div className={styles.integrationCodeEditButton}>
                  <img src={document} alt='' />
                  <a
                    href={codeLib.link}
                    rel='noreferrer'
                    target='_blank'
                    onClick={() => {
                     switch (codeLib.name) {
                       case 'react':
                         amplitude.track(
                          events.HOMEPAGE_REACT_DOCUMENTATION_CLICK
                         )
                         break
                       case "reactNative":
                         amplitude.track(
                          events.HOMEPAGE_REACT_NATIVE_DOCUMENTATION_CLICK
                         )
                         break
                       case "flutter":
                         amplitude.track(
                           events.HOMEPAGE_FLUTTER_DOCUMENTATION_CLICK
                         )
                         break
                       case "node":
                         amplitude.track(
                           events.HOMEPAGE_NODE_DOCUMENTATION_CLICK
                         )
                         break
                       case "solidity":
                         amplitude.track(
                          events.HOMEPAGE_SOLIDITY_DOCUMENTATION_CLICK
                         )
                         break
                       default:
                         // Handle default case
                         break
                     }
                    }}
                  >
                    Documentation
                  </a>
                </div>
                <div
                  className={styles.integrationCodeEditButton}
                  onClick={handleCopyCode}
                >
                  <img src={duplicate} alt='' />
                  Copy
                </div>
              </div>
              <div className={styles.integrationCodeEditWrapper}>
                <SyntaxHighlighter language='js' style={myMaterialTheme}>
                  {codeLib.code}
                </SyntaxHighlighter>
              </div>
            </div>
          </div>
          <div className={styles.questionBlockWrapper}>
            <div className={styles.questionBlockAnswer}>
              <h2 className={styles.questionBlockTitle}>
                Frequently Asked Questions
              </h2>
              <div className={styles.questionBlockAnswerText}>
                <h5 className={styles.questionAnswerTitle}>{answer.title}</h5>
                {answer.text.map((el, index) => (
                  <div key={index}>
                    <p className={styles.questionAnswerDescription}>{el}</p>
                    <br />
                  </div>
                ))}
              </div>
            </div>
            <div className={styles.questionButtonWrapper}>
              {faq.map((el, index) => (
                <div
                  key={index}
                  className={clsx(styles.questionButton, {
                    [styles.questionButtonAction]: index === answerIndex,
                  })}
                  onClick={() => handleFaq(el.answer, index, el.question)}
                >
                  <img
                    src={chevron}
                    className={
                      index === answerIndex ? styles.сhevronActive : ""
                    }
                    alt=''
                  />
                  {el.question}
                </div>
              ))}
            </div>
            <div className={styles.questionButtonWrapperMobile}>
              {faq.flatMap((el, index) => [
                <div
                  key={index}
                  className={clsx(styles.questionButton, {
                    [styles.questionButtonAction]: index === answerIndex,
                  })}
                  onClick={() => handleFaq(el.answer, index, el.question)}
                >
                  <img
                    src={chevron}
                    className={
                      index === answerIndex ? styles.сhevronActive : ""
                    }
                    alt=''
                  />
                  {el.question}
                </div>,
                index === answerIndex && (
                  <div className={styles.questionBlockAnswerTextMobile}>
                    {answer.text.map((text) => (
                      <Fragment key={text}>
                        <p className={styles.questionAnswerDescription}>
                          {text}
                        </p>
                        <br />
                      </Fragment>
                    ))}
                  </div>
                ),
              ])}
            </div>
          </div>
          <div className={styles.blueBlock}>
            <h3 className={styles.blueBlockTitle}>
              Get integrated in under 10 minutes{" "}
            </h3>
            <div className={styles.mockupBlockButton}>
              <a
                style={{ cursor: "pointer" }}
                onClick={() => {
                  handleSignIn()
                  amplitude.track("Homepage_footer_CTA-login_click")
                }}
              >
                Get started
              </a>
              <img src={arrowRight} alt='' />
            </div>
          </div>
        </div>
        <Footer />
      </>
    )
}

export default App;
